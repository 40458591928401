import i18next from "i18next";

/* eslint-disable no-param-reassign */
class GridUtils {
   constructor() {
      if (GridUtils._instance) return GridUtils._instance;

      this.whitelist = ["width", "hide", "sortDirection"];

      GridUtils._instance = this;
   }

   changeFilterParams(columns) {
      return columns.map((m) => {
         if (m.filter === "agDateColumnFilter") {
            const filterParams = {};
            Object.assign(filterParams, m.filterParams, {
               suppressAndOrCondition: true
            });
            Object.assign(m, { filterParams });
         }

         if (m.filterParams) {
            if (this._hasOwnProperty(m.filterParams, "clearButton")) {
               delete m.filterParams.clearButton;
            }
            // if (this._hasOwnProperty(m.filterParams, "clearButton")) {
            //    if(m.filterParams.clearButton === true) {
            //       if(m.filterParams.buttons) {
            //          m.filterParams.buttons.push("clear");
            //       } else {
            //          Object.assign(m.filterParams, { buttons: ["clear"], closeOnApply: true});
            //       }
            //    }
            //    delete m.filterParams.clearButton;
            // }

            if (this._hasOwnProperty(m.filterParams, "applyButton")) {
               if (m.filterParams.applyButton === true) {
                  if(m.filterParams.buttons && Array.isArray(m.filterParams.buttons)) {
                     if(!m.filterParams.buttons.includes("apply")) m.filterParams.buttons.push("apply");
                  } else {
                     Object.assign(m.filterParams, { buttons: ["apply"], closeOnApply: true });
                  }
               }

               delete m.filterParams.applyButton;
            }
         }
         return m;
      });
   }

   /*
   Redux에 있는 값을 가지고 복사할려고 할때 주의 해야함.
   => 인스턴스 자체를 접근해서 변경하는것이기 때문에 Redux-sync에서 Exception 일어남
    */
   mergeFilterParams(t, source) {
      const target = this.changeFilterParams(t.slice());

      const result = target.filter(a => source.filter(b => a.field === b.field).length > 0);
      source.filter(a => target.filter(b => a.field === b.field).length === 0)
         .reduce((a, v) => {
            a.push(v);
            return a;
         }, result);

      const ignore = (this.whitelist||[]).reduce((a, v) => {
         const vv = v.split(".");
         a.push(vv);
         return a;
      }, []);

      return result.map((m) => {
         const src = source.find(a => a.field === m.field);
         if(this._hasOwnProperty(src, "resizable") && !JSON.parse(src.resizable)) {
            Object.assign(m, {width: src.width});
         }

         this._deepCopy(m, src, ignore);
         this._deleteAttrs(m, src, ignore);
         return m;
      });
   }

   _deleteAttrs(target, source, ignore, index = 0) {
      const check = ignore.filter(f => f[index] !== undefined).map((m) => { return { len: m.length, attrs: m[index]}; });
      Object.keys(target).forEach((key) => {
         const find = check.find(f => f.attrs === key);
         if(find === undefined || find.len !== (index + 1)) {
            if(!this._hasOwnProperty(source, key)) {
               delete target[key];
            }
            else if(this._isObject(target[key])) {
               this._deleteAttrs(target[key], source[key], ignore, index+1);
            }
         }
      });
   }

   _deepCopy(target, source, ignore, index = 0) {
      const check = ignore.filter(f => f[index] !== undefined).map((m) => { return {len: m.length, attrs: m[index]}; });

      Object.keys(source).forEach((key) => {
         const find = check.find(f => f.attrs === key);
         if(find === undefined || find.len !== (index + 1)) {
            if(!this._hasOwnProperty(target, key)) {
               Object.assign(target, {[key]: source[key]});
            }
            else if(this._isObject(source[key])) {
               this._deepCopy(target[key], source[key], ignore, index+1);
            } else {
               Object.assign(target, {[key]: source[key]});
            }
         } else if(!this._hasOwnProperty(target, key)) {
            Object.assign(target, {[key]: source[key]});
         }
      });
   }

   _hasOwnProperty(obj, key) {
      return Object.prototype.hasOwnProperty.call(obj, key);
   }

   _getType(target) {
      return Object.prototype.toString.call(target).slice(8, -1);
   }

   _isObject(target) {
      return this._getType(target) === "Object";
   }

   pfCellRenderer(value) {
      switch(value) {
      case "begin":
         return `<hpacs-spinner class="begin"></hpacs-spinner>`;
      case "success":
         return `<iron-icon class="prefetchIcon success" icon="healthhub:prefetchDone"></iron-icon>`;
      case "fail":
      case "empty":
         return `<iron-icon class="prefetchIcon fail" icon="healthhub:prefetchErr"></iron-icon>`;
      default:
         return `<iron-icon class='prefetchIcon none' icon='vaadin:thin-square'></iron-icon>`;
      }
   }

   emCellRenderer(value) {
      switch(value) {
      case "normal":
         return `<div class="normal">N</div>`;
      case "em":
         return `<div class="emergency">E</div>`;
      case "em_cvr":
         return `<div class="emergency">CVR</div>`;
      case "normal_cvr":
         return `<div class="normal">CVR</div>`;
      default:
         return `<div class="normal">${value}</div>`;
      }
   }

   rsCellRenderer(value) {
      switch(value) {
      case "3A":
         return "A";
      case "1W":
         return "W";
      default:
         return "O";
      }
   }

   psCellRenderer(value) {
      switch(value) {
      case "F":
         return "F";
      case "M":
         return "M";
      default:
         return "O";
      }
   }

   verifyCellRenderer(params) {
      if(params.data.verifyEmail)
         return `${params.value} (${params.data.verifyEmail})`;
      return params.value;
   }

   compareArray(arr1, arr2) {
      let result = false;

      // 길이가 다르면 다른 배열이라고 판단
      if (arr1.length !== arr2.length) return result;

      arr1.forEach((item) => {
         const i = arr2.indexOf(item);
         if (i > -1) arr2.splice(i, 1);
      });

      // compare2의 길이가 0이면 동일하다고 판단.
      result = arr2.length === 0;

      return result;
   }

   disableRowDeselectionBySpace(params) {
      // #18714 [HPACS] Fleader 로 Macro 사용 (넥스트필름박스가 들어가는 매크로) 오류사항
      const isSpace = params.event.code === "Space";

      // ag-grid cell에서 space key 입력시 기본동작으로 row selection이 풀리게 되어있어 해당 기능을 막아줌
      if(isSpace) {
         // 기능을 막아버리면 ag-grid의 scroll이 움직이게되어 (브라우저에서 space입력시 기본동작임)event를 막고 강제로 fleader에 space 이벤트 전파
         params.event.preventDefault();
         params.event.stopPropagation();
         const keyEvent = new KeyboardEvent("keypress", {
            code: "Space",
            keyCode: 32,
            which: 32
         });
         window.dispatchEvent(keyEvent);
      }


      return  isSpace;
   }

   localeText() {
      return {
         copy: i18next.t("label.agGridLocaleText.copy"),
         copyWithHeaders: i18next.t("label.agGridLocaleText.copyWithHeaders"),
         csvExport: i18next.t("label.agGridLocaleText.csvExport"),
         excelExport: i18next.t("label.agGridLocaleText.excelExport"),
         paste: i18next.t("label.agGridLocaleText.paste"),
         export: i18next.t("label.agGridLocaleText.export"),
         excelXmlExport: i18next.t("label.agGridLocaleText.excelXmlExport")
      };
   }
}

const gridUtils = new GridUtils();
Object.freeze(gridUtils);

export default gridUtils;
